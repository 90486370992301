import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';
import { MessagingChannelIndicatorComponent } from './messaging-channel-indicator.component';

@NgModule({
  exports: [MessagingChannelIndicatorComponent],
  declarations: [MessagingChannelIndicatorComponent],
  imports: [CommonModule, TooltipModule]
})
export class MessagingChannelIndicatorModule {}
