import { Component, input, output } from '@angular/core';

@Component({
  selector: 'app-messaging-channel-indicator',
  templateUrl: './messaging-channel-indicator.component.html',
  styleUrl: './messaging-channel-indicator.component.scss'
})
export class MessagingChannelIndicatorComponent {
  public isChannelUnread = input.required<boolean>();
  public isChannelDisabled = input.required<boolean>();

  /**
   * Emits the alias to a parent method for navigation
   */
  public goToChat = output<void>();
}
