import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { IConnectionCenterState } from './connection-center.model';
import { ConnectionCenterStore } from './connection-center.store';

@Injectable({ providedIn: 'root' })
export class ConnectionCenterQuery extends Query<IConnectionCenterState> {
  public requests$ = this.select((s) => s.requests);
  public connections$ = this.select((s) => s.connections);

  public constructor(protected store: ConnectionCenterStore) {
    super(store);
  }
}
